import { useState, useLayoutEffect, useEffect } from 'react';

export function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    const updateSize = () => {
      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
      let canvasWidth = iOS
        ? document.documentElement.clientWidth
        : window.innerWidth
      let canvasHeight = iOS
        ? document.documentElement.clientHeight
        : window.innerHeight

      setSize([canvasWidth, canvasHeight])
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export function useIsPortrait() {
  const [screen, setScreen] = useState({ portrait: false, phone: false });
  const windowSize = useWindowSize();

  useEffect(() => {
    //setPortrait(windowSize[1] > windowSize[0]);
    const portrait = windowSize[1] > windowSize[0];
    setScreen({ portrait, phone: windowSize[portrait ? 0 : 1] < 500 });
  }, [windowSize]);

  return screen;
}