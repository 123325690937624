import React from 'react'
import Box from '@mui/material/Box';
import { colorPrimary, colorBrandDark } from '../style/themes';
import { CAROUSEL_IMAGES as images } from '../constants/images'

const MasonryImage = ({ data }) => {
  return <img style={{ width: '100%', objectFit: 'cover' }} src={data.img + '?w=240&h=240&fit=crop'} alt={data.title} />;
};

const MasonryTitle = ({ title }) => {
  return <Box sx={{
    display: 'flex',
    //flex: '0 1 100%',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    backgroundColor: colorBrandDark,
    color: colorPrimary,
    fontSize: '2.5vh',
    fontWeight: 500,
    padding: '7.5%',
    paddingBottom: '10%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }}>{title}</Box>
};

const MasonryPiece = ({ pieces }) => {
  const flexRow = { display: 'flex', flex: '1 1 0', flexDirection: 'row' };
  const flexColumn = { display: 'flex', flex: '1 1 0', flexDirection: 'column' };
  const flexPiece = {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'strech',
    justifyContent: 'strech',
    padding: '0.5em',
  };
  return (
    <Box sx={{...flexRow}}>
      <Box sx={{...flexColumn}}>
        <Box sx={{ flex: '4 0 0', ...flexPiece }}>
          { pieces[0].img ? <MasonryImage data={pieces[0]}/> : <MasonryTitle title={pieces[0].title}/> }
        </Box>
        <Box sx={{ flex: '2.5 0 0', ...flexPiece }}>
          { pieces[1].img ? <MasonryImage data={pieces[1]}/> : <MasonryTitle title={pieces[1].title}/> }
        </Box>
        <Box sx={{ flex: '4 0 0', ...flexPiece }}>
          { pieces[2].img ? <MasonryImage data={pieces[2]}/> : <MasonryTitle title={pieces[2].title}/> }
        </Box>
      </Box>
      <Box sx={{...flexColumn}}>
        <Box sx={{ flex: '0.75 0 0'}}></Box>
        <Box sx={{ flex: '2.5 0 0', ...flexPiece }}>
        { pieces[3].img ? <MasonryImage data={pieces[3]}/> : <MasonryTitle title={pieces[3].title}/> }
        </Box>
        <Box sx={{ flex: '4 0 0', ...flexPiece }}>
          { pieces[4].img ? <MasonryImage data={pieces[4]}/> : <MasonryTitle title={pieces[4].title}/> }
        </Box>
        <Box sx={{ flex: '2.5 0 0', ...flexPiece }}>
          { pieces[5].img ? <MasonryImage data={pieces[5]}/> : <MasonryTitle title={pieces[5].title}/> }
        </Box>
        <Box sx={{ flex: '0.75 0 0' }}></Box>
      </Box>
    </Box>
  );
}

export default function CarouselImages() {
  const chunkSize = 6;
  let chunks = []
  const imageArray = images.slice(0, 36);
  for (let i = 0, j = imageArray.length; i < j; i += chunkSize) {
    const pieces = imageArray.slice(i, i + chunkSize);
    chunks.push(<MasonryPiece key={i} pieces={pieces}/>);
  }
  return (
    <Box sx={{ display: 'flex', height: '80vh', flex: '1 0 0' }}>
      {chunks}
    </Box>
  )
}
