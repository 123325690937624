import React, { useRef, useState } from 'react'
import { Box, Button, Link, TextField, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import OneSignal from 'react-onesignal';

import { colorBrandDark, colorBlack, colorWhite, colorSecondary, colorBrandSubtle, colorPrimary, colorFormField } from '../style/themes';
import AppStoreLogos from './AppStoreLogos';

import { useIsPortrait } from '../hooks/useWindowSize';
import { LINKEDIN_URL, INSTAGRAM_URL, FACEBOOK_URL, PRIVACY_URL } from '../constants/urls'

import { ONESIGNAL_APP_ID, CONTACT_EMAIL } from '../constants'
import SimpleDialog from './SimpleDialog';

const SOCIAL_MEDIA_ICON_FONTSIZE = '1.3em';

function Copyright() {
  return (
    <Typography color={colorBrandSubtle} sx={{ marginTop: '3em', fontSize: '0.75em', textAlign: 'center' }} >
      {'Copyright © Nøkkel Technologies Ltd '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Footer({ footerRef }) {  
  const { phone } = useIsPortrait();
  const emailRef = useRef();
  const [email, setEmail] = useState();
  const [openEmailResponse, setOpen] = useState(false);

  const [dialogTitle, setDialogTitle] = useState();
  const [dialogText, setDialogText] = useState();

  const submitEmailForm = async () => {
    if (email) {
      await OneSignal.init({ appId: ONESIGNAL_APP_ID });
      const response = await OneSignal.setEmail(email);
      console.log('response', response);
      setOpen(true);
      setDialogTitle('Subscribed!')
      setDialogText(`Email address "${email}" has been added to our mailing list. We will keep you up to date with all upcoming Nøkkel news.`)
      setEmail('');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      ref={emailRef} 
      component="footer"
      sx={{
        zIndex: 5,
        py: '3vh',
        px: '2vw',
        mt: 'auto',
        backgroundColor: colorBlack,
      }}
    >
      <Box ref={footerRef} sx={{ width: '86vw', margin: 'auto' }}>
        <Box sx={{ display: 'flex', gap: phone ? 0 : '10vw', flexDirection: phone ? 'column' : 'row', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flex: '2 2 0', backgroundColor: colorBrandDark, py: '2.5em', justifyContent: 'center' }}>
            <Box sx={{ maxWidth: '90%', flex: '0 0 auto', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography color={colorPrimary} sx={{ lineHeight: '18vh', fontWeight: 'bold', fontSize: phone ? '6vw' : '2.9vw' }}>Start Nøkkeling now</Typography>
              <AppStoreLogos height={phone ? '10vw' : '3vw' }/>
            </Box>
            
          </Box>
          <Box sx={{ flex: '2 2 0', padding: '7em 0em 3em 0em' }}>
            <Box sx={{ color: colorWhite, fontSize: '1.5em', fontWeight: 'bold' }}>Be the first to know</Box>
            <Box sx={{ lineHeight: '2em', color: colorWhite, fontSize: '1em' }}>Sign up for occasional news and feature updates.</Box>
            <TextField
              onChange={e => setEmail(e.target.value)}
              value={email}
              id="standard-name"
              label="Email"
              variant="filled"
              sx={{ maxWidth: '28em', borderRadius: '0.3vw', my: '1.5em', display: 'flex', backgroundColor: colorFormField }}
              InputLabelProps={{ style: { color: colorBrandSubtle }}}
              autoComplete='email'
              InputProps={{ 
                //onFocus: () => emailRef.current.scrollIntoView(),
                onBlur: e => e.preventDefault(),
                style: { color: colorWhite },
                endAdornment: <Button onClick={submitEmailForm} sx={{ fontWeight: 200, color: colorWhite, textTransform: 'none' }}>Submit</Button> }}
            />
            <Link target="_blank" rel="noopener" color='secondary' underline="none" href={`mailto:${CONTACT_EMAIL}`}>
              Need anything else? Contact us at {CONTACT_EMAIL}
            </Link>
            <Box sx={{ height: '4em' }}/>
              <Box sx={{ my: '2em', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '7em' }}>
                <IconButton sx={{ fontSize: SOCIAL_MEDIA_ICON_FONTSIZE }} href={LINKEDIN_URL} target="_blank" rel="noopener">
                  <FontAwesomeIcon color={colorSecondary} icon={faLinkedinIn} />
                </IconButton>
                <IconButton sx={{ fontSize: SOCIAL_MEDIA_ICON_FONTSIZE }} href={INSTAGRAM_URL} target="_blank" rel="noopener" >
                  <FontAwesomeIcon color={colorSecondary} icon={faInstagram} />
                </IconButton>
                <IconButton sx={{ fontSize: SOCIAL_MEDIA_ICON_FONTSIZE }} href={FACEBOOK_URL} target="_blank" rel="noopener">
                  <FontAwesomeIcon color={colorSecondary} icon={faFacebookF} />
                </IconButton>
              </Box>
            {/*<Box sx={{ my: '0.5em' }}>
              <Link href={CONTACT_URL} ref={contactLinkRef} color='secondary' underline="none" >Contact</Link>
            </Box>*/}
            
            <Box>
              <Link target="_blank" rel="noopener" color='secondary' underline="none" href={PRIVACY_URL}>Privacy</Link>
            </Box>
            <Box>
            </Box>
          </Box>
        </Box>
        
        {/*<Divider sx={{ m: 2 }} light color={colorBrandLightSemiTrans}/>*/}
        <Typography variant="body1" color="#fff">
          
        </Typography>
        <Copyright />
      </Box>
      <SimpleDialog
        title={dialogTitle}
        text={dialogText}
        open={openEmailResponse}
        handleClose={handleClose}/>
    </Box>
  )
}
