import { createTheme } from '@mui/material';

const COLOR_VIOLET = '#5f63e1';
const COLOR_VIOLET_SEMI_TRANS = '#5f63e188';
const COLOR_DEEP_PURPLE = '#09003d';
const COLOR_MIST = '#f2f2f7';
const COLOR_BLUE_SMOKE = '#aeafc7';
const COLOR_WHITE = '#ffffff';
const COLOR_BLACK = '#000000';
const COLOR_FADED_PURPLE = '#635E81';
const COLOR_DARK_SMOKE = '#24222C';

export const colorPrimary = COLOR_VIOLET;
export const colorSecondary = COLOR_BLUE_SMOKE;
export const colorBlack = COLOR_BLACK;
export const colorWhite = COLOR_WHITE;
export const colorBrandDark = COLOR_DEEP_PURPLE;
export const colorBrandSubtle = COLOR_FADED_PURPLE;
export const colorBrandLight = COLOR_VIOLET;
export const colorBrandGray = COLOR_MIST;
export const colorBrandLightSemiTrans = COLOR_VIOLET_SEMI_TRANS;
export const colorFormField = COLOR_DARK_SMOKE;

export default createTheme({
  typography: {
    fontFamily: ['Libre Franklin'],
  },
  palette: {
    mode: 'light',
    primary: {
      main: COLOR_VIOLET,
    },
    secondary: {
      main: COLOR_BLUE_SMOKE,
    },
    grey: COLOR_MIST,
  },
  display: 'flex',
  shape: {
    borderRadius: 10,
  },
});