import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

import CloseIcon from '@mui/icons-material/Close';

import YouTubeVideo from './YouTubeVideo';
import { YOUTUBE_VIDEO_ID } from '../constants/urls';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function YouTubeDialog({ open, handleClose }) {
  const embedId = YOUTUBE_VIDEO_ID;
  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      TransitionComponent={Transition}
      //keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ marginBottom: '-1%' }}
      //PaperProps={{ sx: { width: '100%', maxHeight: 'unset', m: 0, backgroundColor: 'black' }}}
      PaperProps={{ sx: { maxWidth: '140vh', width: '100%', height: '90%', maxHeight: 'unset', m: 0, backgroundColor: 'black' }}}
    >
      {/*<DialogTitle>{"Use Google's location service?"}</DialogTitle>*/}
      <DialogActions onClick={handleClose} sx={{ color: 'white'}}><CloseIcon/></DialogActions>
      <DialogContent sx={{ padding: 0  }}>
        <YouTubeVideo embedId={embedId}/>
      </DialogContent>
    </Dialog>
  );
}
