export const HERO_IMAGE = 'https://images.unsplash.com/photo-1630305090278-ee07c6ec3213?auto=format&fit=crop&w=687';

export const CAROUSEL_IMAGES = [
  { title: 'Modern living' },
  { img: 'https://images.unsplash.com/photo-1571654443889-863482ff3f42?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1608387371413-f2566ac510e0?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&w=600&q=60' },
  { title: 'More space' },
  { img: 'https://images.unsplash.com/photo-1504363014482-e47ef8dfa815?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1582530491957-8c4f3f214bee?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1598269076100-80192584db82?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1554895345-09be763a7cd9?auto=format&fit=crop&w=600&q=60' },
  //{ img: 'https://images.unsplash.com/photo-1576242958249-6ec1fdcded7e?auto=format&fit=crop&w=600&q=60' },
  { title: 'City lights' },
  { img: 'https://images.unsplash.com/photo-1637271285892-3d8920250fdf?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1504615755583-2916b52192a3?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1594800083755-8fe31b2c99df?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1621294015009-b26e7d266602?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1603090067595-c3febef4765f?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1561966130-ce10f3746233?auto=format&fit=crop&w=600&q=60' },
  { title: 'Weekend bolthole' },
  { img: 'https://images.unsplash.com/photo-1607329295761-9b19c8c9e7f0?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1626433879603-5358393db3bc?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1589222116103-cedd9c2b7738?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1460533893735-45cea2212645?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1578758509716-b9d9aabb85ec?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1621262331990-19861196e3ce?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1566384015402-e496a1dca153?auto=format&fit=crop&w=600&q=60' },
  { title: 'Village life' },
  { img: 'https://images.unsplash.com/photo-1591027590129-4de51a2fb3f6?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1533205269040-5db52ea32e14?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1579627559431-e9082454f2c0?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1600696181681-e94e0253f40c?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1604166167486-af78ebd50897?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1583070643481-901bcefa966f?auto=format&fit=crop&w=600&q=60' },
  { title: 'By the sea' },
  { img: 'https://images.unsplash.com/photo-1572549264486-4a9da1fc0cea?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1618594332469-0364caa48aa9?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1551514716-ecf5d56078ed?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1565589992055-c5509ad498b1?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1549313861-33587f3d2956?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1549313861-33587f3d2956?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1549313861-33587f3d2956?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1549313861-33587f3d2956?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1549313861-33587f3d2956?auto=format&fit=crop&w=600&q=60' },
  { img: 'https://images.unsplash.com/photo-1549313861-33587f3d2956?auto=format&fit=crop&w=600&q=60' }
];