import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import PlayIcon from '@mui/icons-material/PlayArrow';
import { useIsPortrait } from '../hooks/useWindowSize';
import YouTubeDialog from './YouTubeDialog';

const phoneImage = 'iPhone-Granite-vale';
const showVideoLink = true;

function VideoLink() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (!showVideoLink) {
    return <Box style={{ height: '2vh' }}/>;
  }
  return (
    <>
      <Button 
        onClick={handleClickOpen}
        sx={{
          zIndex: 3,
          paddingX: '2em',
          paddingY: '0.25em',
          alignSelf: 'flex-start',
          marginTop: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '1.5em',
          border: '2px solid white',
          backgroundColor: '#ffffff40',
          color: 'white',
          textTransform: 'none',
        }}>
        <PlayIcon fontSize='large' sx={{ marginRight: '0.25em', marginLeft: '-4px' }} />
        <Typography style={{ paddingTop: '2px', fontWeight: 500 }}>How it works</Typography>
      </Button>
      <YouTubeDialog open={open} handleClose={handleClose}/>
    </>
  );
}

function MainFeaturedPost(props) {
  const { post } = props;
  const { portrait, phone } = useIsPortrait();

  return (
    <Box
      sx={{
        zIndex: 2,
        position: 'relative',
        color: '#fff',
        backgroundImage: 'linear-gradient(black 90%, rgba(0,0,0,0))',
      }}
    >
      {/* Increase the priority of the hero background image */}
      <Box
      sx={{
        display: 'flex',
        position: 'relative',
        color: '#fff',
        overflow: phone && portrait ? 'unset' : 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        height: phone && portrait ? 'unset' : '100vh'
      }}
    >
        <Box sx={{ margin: phone ? '-6vw' : '-3vw' }}>
          <img style={{ 
            display: 'flex', 
            width: phone && portrait ? 'auto' : '106vw', 
            height: phone && portrait ? '150vh' : 'auto',
            filter: 'blur(8px)',
            transform: phone && portrait ? 'translate(20vw, -43vh)' : '',
            opacity: '0.8',
          }} src={post.image} alt={post.imageText} />
        </Box>
      </Box>
      {phone && portrait && (
        <>
          <Box sx={{
            position: 'absolute',
            left: '50%',
            top: '50vh',
          }}>
            <img style={{ width: '180vw', transform: 'translate(-50%, -15%)' }} alt='Phone App' src={`${phoneImage}${''}.png`}/>
          </Box>
          <Box sx={{ display: 'flex', pt: '0vh', pb: '30vh', justifyContent: 'center' }}>
            <VideoLink/>
          </Box>
        </>
      )}
      <Box
        sx={{
          position: 'absolute',
          top: phone && portrait ? '15vh' : '30vh',
          bottom: 0,
          right: 0,
          left: 0,
          justifyContent: 'center',
          display: 'flex',
          minHeight: '40em',
        }}
      >
        <Box sx={{
          width: phone && portrait ? '88vw' : '32vw',
          height: '28vw',
          marginRight: phone && portrait ? 0 : '48vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          <Typography style={{ fontWeight: 700, fontSize: phone && portrait ? '13vw': '5.2vw', lineHeight: '115%'  }}>
            {post.title}
          </Typography>
          <Typography sx={{ fontSize: phone && portrait ? '5.5vw' : '1.75vw', py: '3vh' }}>
            {post.description}
          </Typography>
          {!phone && <VideoLink/>}
          {/*<Link variant="subtitle1" href="#">
            {post.linkText}
          </Link>*/}
          {phone && !portrait && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <VideoLink/>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainFeaturedPost;