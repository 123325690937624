import { ThemeProvider } from '@mui/material';
import theme from './style/themes'
import PageContainer from './components/PageContainer'

if (process.env.NODE_ENV === 'production' && window.location.host !== 'nokkel.com') {
  window.location.host = 'nokkel.com';
}
console.log(process.env.NODE_ENV)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <PageContainer/>
    </ThemeProvider> 
  );
}

export default App;
