import React, { useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
//import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import HomePage from './HomePage';
import { colorBlack } from '../style/themes';

import Footer from './Footer';
import CookiesBanner from './CookiesBanner';
//import SourceCode from './SourceCode';

export default function PageContainer() {
  const footerRef = useRef();
  /*const [isRotated, setIsRotated] = useState(false);


  const flip = useCallback(() => {
    setIsRotated(state => !state);
  }, []);*/

  /* <div className={`card ${isRotated ? 'rotated' : ''}`}> */
  return (  
    <div>
      <Box
        className="front"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: colorBlack
        }}
      >
        <CssBaseline />
        <HomePage footerRef={footerRef}/>
        {/* <Footer flip={flip} footerRef={footerRef}/> */}
        <Footer footerRef={footerRef}/>
        <CookiesBanner/>
      </Box>
      {/*<Box
        className="back"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: 'rgb(0,0,0,0.25)',
            minHeight: '100vh',
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
          }}
        >
          Careers
          <Button onClick={flip}>FLiiiiiiiip!</Button>

        </Box>
        <SourceCode />
        </Box>*/}
    </div>
  );
}