import { Typography, Box, Button } from '@mui/material';
import React from 'react';
import Hero from './Hero';
import Carousel from './Carousel';
import { colorWhite, colorBlack, colorPrimary } from '../style/themes';
import { ReactComponent as WhiteLogo } from '../assets/logo.svg'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import { useIsPortrait } from '../hooks/useWindowSize';

import { HERO_IMAGE } from '../constants/images';

const heroImage = HERO_IMAGE;// `${'r-architecture-2gDwlIim3Uw-unsplash'}${''}.png`;
const phoneImage = `${'iPhone-Granite-vale'}${''}.png`;

const mainFeaturedPost = {
  title: 'Unlock your dream home',
  description: 'Instant insight into any property, find out if it’s the perfect match.',
  image: heroImage,
  //linkText: 'Continue reading…',
};

const AppCaption = ({ title, description }) => {
  const { portrait, phone } = useIsPortrait();
  const stacked = portrait || phone;
  return (
    <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: stacked ? 'column' : 'row', gap: stacked ? '2vw' : '5vw' }}>
        <Typography sx={{ width: stacked ? '45vw' : '14vw', fontWeight: 600, fontSize: phone ? '5.4vw' : stacked ? '3.4vw': '1.7vw' }}>
          {title}
        </Typography>
        <Typography sx={{ width: stacked ? '45vw' : '21vw', fontWeight: 200, fontSize: phone ? '3.6vw' : stacked ? '2.4vw' : '1.2vw' }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

export default function HomePage({ footerRef }) {
  const { portrait, phone } = useIsPortrait();
  const phoneImageStyle = phone
    ? { position: 'fixed', zIndex: portrait ? 1 : 4, left: '75%', top: '50%', transform: portrait ? 'translate(-50%, -50%)' : 'translate(-50%, -30%)' }
    : { position: 'fixed', zIndex: 2, transform: 'translate(-50%, 20%)', left: '70vw', bottom: '0vh' }
    //: { position: 'fixed', zIndex: 2, left: 'calc(50% - 9vw)', top: '1.5vh' }
  return (
    <>
      <Box sx={[
        { zIndex: 5, position: 'fixed', p: '2em', display: 'flex', justifyContent: phone ? 'flex-start' : 'center', alignItems: 'baseline' },
        phone ? {} : { left: '50%', transform: 'translateX(-50%)' }
      ]}>
        <WhiteLogo fill={colorWhite} height='1.6em' style={{ left: 0 }} />
      </Box>
      {!phone && (<Box sx={{ color: 'white', zIndex: 4, position: 'absolute', mt: '3.7em', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'baseline' }}>
        <Typography sx={{ fontSize: '1.2em', fontWeight: 600 }}>Nøkkel</Typography>
      </Box>)}
      <Button
        onClick={() => footerRef.current.scrollIntoView({ behavior: 'smooth' })}
        sx={{ 
          color: 'white',
          backgroundColor: colorBlack,
          zIndex: 4,
          position: 'absolute',
          right: 0,
          top: 0,
          px: '1.4em',
          lineHeight: '2em',
          border: `2px solid ${colorPrimary}`,
          m: '1.5em',
          display: 'flex',
          alignItems: 'center',
          gap: '0.8em',
          textTransform: 'none',
          borderRadius: 0,
          fontSize: phone && portrait ? '4vw' : '0.93em',
        }}
      >
        <PhoneIphoneOutlinedIcon fontSize='small' />
        <Box sx={{ fontWeight: '500' }}>Download the app</Box>        
      </Button>
      <Hero post={mainFeaturedPost}/>
      <Box sx={phoneImageStyle}>
        <img style={{ width: phone ? '100vw' : '120vh', maxWidth : '90vw' }} alt='Phone App' src={phoneImage}/>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', color: 'white', alignItems: 'flex-end', marginRight: '48vw' }}>
        <AppCaption
          title={'See the home of your dreams'}
          description={'Nøkkel is the free app that lets you capture properties you love when you see them - even if they are not on the market.'}
        />
        <AppCaption
          title={'Save it to Nøkkel'}
          description={'With only a tiny percentage of properties for sale at any time, why settle for what is available? With Nøkkel, you can plan ahead by creating a wishlist of the houses you are interested in.'}
        />
        <AppCaption
          title={'Connect directly with the owner'}
          description={'Not only will we let you know when they come to the market, we can also find out if the owner is open to chat about a possible sale.'}
        />
      </Box>
      <Box sx={{ zIndex: 5, backgroundImage: 'linear-gradient(rgba(0,0,0,0), black 25vh)', paddingTop: '50vh', paddingBottom: 'calc(50vh + 80vh)', overflow: 'hidden'   }}>
        <Carousel/>
      </Box>
    </>
  );
}
