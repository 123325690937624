import { useState, useEffect } from "react";

function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  console.log('set', key, saved);
  return initial || defaultValue;
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    if (value != null) {
        localStorage.setItem(key, JSON.stringify(value));
        console.log('set', key, value);
    } else {
        localStorage.removeItem(key);
        console.log('remove', key, value);
    }
  }, [key, value]);

  return [value, setValue];
};