import { Box, Button, Link, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react'
import ReactPixel from 'react-facebook-pixel';
import { PRIVACY_URL } from '../constants/urls';

import { useLocalStorage } from '../hooks'
import { FACEBOOK_PIXEL_ID, GOOGLE_ANALYTICS_ID } from '../constants'

import ReactGA from 'react-ga4';

export default function CookiesBanner({ style }) {

  const [cookiesPolicy, setCookiesPolicy] = useLocalStorage('cookies-policy')

  const isProduction = useMemo(() => process.env.NODE_ENV === 'production', []);

  useEffect(() => {
    if (isProduction) {
      ReactPixel.init(FACEBOOK_PIXEL_ID);
    }
  }, [isProduction])

  useEffect(() => {
    if (isProduction) {
      if (cookiesPolicy === 'accept') {
        ReactPixel.grantConsent();
        ReactPixel.pageView();
        ReactGA.initialize(GOOGLE_ANALYTICS_ID);
        ReactGA.send("pageview");
      } else {
        ReactPixel.revokeConsent();
      }
    }
  }, [cookiesPolicy, isProduction]);

  if (cookiesPolicy === 'accept') {
    ReactPixel.grantConsent();
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', backgroundColor: 'white', position: 'fixed', padding: '3vh', width: '100vw', bottom: 0, zIndex: 10 }}>
      <Box style={{ display: 'flex', flexDirection: 'column', flex: '0 1 auto' }}>
        <Typography>
          To provide the best experience on our website we use cookies
        </Typography>
        <Link target="_blank" href={PRIVACY_URL} style={{ textDecoration: 'none' }}> learn more</Link>
      </Box>
      <Button sx={{ mx: '2vw' }} variant="outlined" onClick={() => setCookiesPolicy('accept')}>Accept</Button>
    </Box>
  )
}
