import React from "react";
import PropTypes from "prop-types";

const YouTubeVideo = ({ embedId }) => {
  return (
    <div className="video-container">
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}

YouTubeVideo.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YouTubeVideo;