import * as React from "react";
import { motion } from 'framer-motion/dist/es/index';
import CarouselImages from "./CarouselImages";

export default function Carousel() {

  const carouselStyle = {
    position: 'absolute',
    left: 0,
    width: '600vh',
    display: 'flex',
    flexDirection: 'row',
  }
  return (
    <motion.div
      style={carouselStyle}
      animate={{ x: "-300vh" }}
      initial={{ x: "0" }}
      transition={{
        ease: "linear",
        repeat: "Infinity",
        duration: 40,
      }}
    >
      <CarouselImages/>
      <CarouselImages/>
    </motion.div>
  );
}